<template>
  <div class="gallery-preview">
    <img
      v-if="fileType === 'image'"
      :src="file.link"
      alt="Image preview"
    />
    <video v-if="fileType === 'video'" class="preview-box">
      <source :src="file.link" />
      Your browser does not support the video tag.
    </video>
    <div
      v-if="fileType === 'generic'"
      class="preview-box flex flex-col justify-center items-center gap-8 py-8"
      :title="file.filename === truncateText(file.filename, 15) ? '' : file.filename"
    >
      <div>
        <i class="far fa-file"></i>
      </div>
      <div class="break-all">
        {{ truncateText(file.filename, 15) }}
      </div>
    </div>
    <div class="preview-date">
      <div>
        <span>{{ file.mtime_ts | shortdate }}</span>
        <span>{{ file.mtime_ts | shorttime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFileType,
  truncateText,
} from '@/helpers';

export default {
  name: 'Preview',
  components: {},
  computed: {
    fileType() {
      return getFileType(this.file);
    },
  },
  props: {
    file: Object,
  },
  data() {
    return {};
  },
  methods: {
    truncateText(text, maxLength) {
      return truncateText(text, maxLength);
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-preview {
  position: relative;
  border-radius: 25px;
  display: flex;
  height: 100%;
  background-color: #eee;
  aspect-ratio: 3 / 4;

  img {
    border-radius: 10px;
    object-fit: cover;
    will-change: transform;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
  }

  i {
    font-size: 32px;
  }

  .preview-box {
    width: 100%;
    padding: 15px 30px;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #eee;
  }

  .preview-date {
    position: absolute;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    color: white;
    text-align: center;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 4px;

    & > div {
      display: flex;
      justify-content: center;
      gap: 8px;
    }
  }
}
</style>
